import * as React from "react";
import Layout from "../components/Layout";
import ServicesPanel from "../components/ServicesPanel";
import useColor from "../context/ColorContext";
import getDecorationData from "../utils/functions/getDecorationData";

const DecorationPage = () => {
    const { setColor } = useColor();
    const services = getDecorationData();

    setColor?.("green");
    return (
        <>
            <Layout>
                <ServicesPanel
                    description="Indywidualne elementy dekoracyjne stworzone do wnętrz prywatnych i komercyjnych."
                    heading={"Dekoracja wnętrz"}
                    services={services}
                    category={"dekoracje"}
                />
            </Layout>
        </>
    );
};

export default DecorationPage;
