import { ServiceData } from "../../components/ServiceCard";

const services: ServiceData[] = [
    {
        title: "Dekoracje do wnętrz na indywidualne zamówienia",
        description:
            "Realizujemy zlecenia na logotypy firm, materiały POS, dekoracje mieszkań, szkół i innych instytucji.",
        imageCode: "1",
    },
    {
        title: "Dekoracje ścienne",
        description:
            "Indywidualnie dopasowane do potrzeb klienta dekoracje wycięte w sklejce, drewnie lub aludibondzie. Dodatkowo podświetlone stanowią niepowtarzalną dekorację ściany.",
        imageCode: "2",
    },
    {
        title: "Fototapety",
        description:
            "Dowolny wzór tapety na nośniku flizelinowym daje możliwość niepowtarzalnej aranżacji wnętrza. Nasze tapety zdobią ściany w obiektach usługowych, domach, galeriach.",
        imageCode: "3",
    },
];

const getDecorationData = () => services;

export default getDecorationData;
